import microShopMini from '@/assets/MicroShop_mini.png';
import { BASE_PATH } from '@/constants/routes';
import { IGetNavBarOptionsRequest } from '@/interfaces/Requests';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { LocalStorageHelper } from '@/lib/helpers/LocalStorageHelper';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import NavBar from '@/Templates/components/NavBar';
import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from '@/Templates/enums/templateEnums';
import { INavBarOptionDto } from '@/Templates/interfaces/templatesInterfaces';
import { ConfigProvider, Flex, Spin } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import zhTW from 'antd/locale/zh_TW';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useGetMerchantIsExpiredApi, useGetNavBarOptionsApi, useGetNavBarSettingApi } from '../../lib/api/apis';
import { GlobalContext } from '../../lib/contexts/GlobalContext';
import AppFooter from './Components/AppFooter';
import FunctionalFloatButtonGroup from './Components/FunctionalFloatButtonGroup';
import MyRoutes from './Components/MyRoutes';


const App: React.FC = () => {
  const localStorageHelper = new LocalStorageHelper();
  const navigate = useNavigate();
  const {
    maxWidth,
    messageApi,
    merchantId,
    shoppingCartItemCount,
    isPageLoading,
    merchantPortalOptionSetting,
    merchantPortalStyleSettings,
    navBarHeight,
    isHideNavBar,
    refetchMerchantPortalOptionSetting,
    setNavBarHeight,
    setNavBarGap,
    deviceType,
    setDeviceType,
    isLogin,
    setIsLogin,
    refreshShoppingCartItemCount,
    refetchMerchantMainMarqueeViewModel
  } = useContext(GlobalContext);
  const { initPortalLanguage, translate, i18nLanguage } = useContext(TranslationContext);
  const { isLoading: isExpiredLoading, data: isExpiredValue } = useQuery('IsExpired', async () => await useGetMerchantIsExpiredApi(merchantId), {
    onError: (error) => {
      messageApi.error('Unknown error');
    }
  });
  const { data: navBarSetting } = useQuery('GetNavBarSetting', () => useGetNavBarSettingApi(merchantId));
  const { data: navBarOptions } = useQuery('GetNavBarOptions', async () => {
    if (!navBarSetting?.result) return [];
    const getItems = async (input: IGetNavBarOptionsRequest): Promise<INavBarOptionDto[]> => {
      const response = await useGetNavBarOptionsApi(input);
      if (!response.isSuccess || !response.result) return [];
      const sortedOptions = response.result.sort((a, b) => a.orderIndex - b.orderIndex);
      for (let option of sortedOptions) {
        if (option.childrenIds.length > 0) {
          option.children = await getItems({ merchantId: input.merchantId, optionIds: option.childrenIds });
        }
      }
      return sortedOptions;
    };
    return await getItems({ merchantId: navBarSetting.result.merchantId, optionIds: navBarSetting.result.navBarOptionIds });
  }, { enabled: !!navBarSetting?.result });


  useEffect(() => {
    refetchMerchantPortalOptionSetting();
    refetchMerchantMainMarqueeViewModel();
    initPortalLanguage();
  }, []);


  useEffect(() => {
    if (isExpiredValue?.result) {
      navigate(`${BASE_PATH}/merchantClosed`);
    }
  }, [isExpiredValue]);

  const [locale, setLocale] = React.useState<Locale>(enUS);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDeviceType(DeviceType.Mobile);
        setNavBarHeight(50);
        setNavBarGap(50);
      } else if (window.innerWidth <= 1024) {
        setDeviceType(DeviceType.LapTop);
        setNavBarHeight(60);
        setNavBarGap(60);
      } else {
        setDeviceType(DeviceType.Desktop);
        setNavBarHeight(60);
        setNavBarGap(60);
      }
    };

    // 初始化设置
    handleResize();

    // 添加事件监听器
    window.addEventListener('resize', handleResize);

    // 清理函数
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (i18nLanguage) {
      case 'zh_TW':
        setLocale(zhTW);
        break;
      case 'zh_CN':
        setLocale(zhCN);
        break;
      default:
        setLocale(enUS);
        break;
    }
  }, [i18nLanguage]);

  if (isExpiredLoading || !merchantPortalOptionSetting) {
    return <LoadingComponent />
  }

  return (
    !isExpiredValue?.result && (
      <>
        <Helmet>
          <meta charSet="UTF-8" />
          <link rel="icon" type="image/*" href={microShopMini} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>{merchantPortalOptionSetting?.merchantName}</title>
        </Helmet>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: "orange",
            },
            components: {
              FloatButton: {
                colorPrimary: "white",
                colorPrimaryHover: "whitesmoke",
              },
            }
          }}
        >
          <NavBar
            BASE_PATH={BASE_PATH}
            shoppingCartItemCount={shoppingCartItemCount}
            isLogin={isLogin}
            setIsLogin={setIsLogin}
            refreshShoppingCartItemCount={refreshShoppingCartItemCount}
            menuItems={navBarOptions || []}
            isHideNavBar={navBarSetting?.result?.isHide || isHideNavBar}
            merchantPortalOptionSetting={merchantPortalOptionSetting}
            navBarHeight={navBarHeight}
            setNavBarHeight={setNavBarHeight}
            setNavBarGap={setNavBarGap}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            translate={translate}
            messageApi={messageApi}
            merchantId={merchantId}
            config={merchantPortalStyleSettings?.filter(r => r.type === MerchantPortalStyleSettingType.Components && r.name === MerchantPortalStyleShopComponents.NavBar)[0]?.styleSetting || {}}
            portalOptionSettingViewModel={merchantPortalOptionSetting}
            portalStyleSettings={merchantPortalStyleSettings}
          />

          {/* Page Loading Cover */}
          <Flex hidden={!isPageLoading} justify="center" align="center" style={{ position: "fixed", height: '100vh', width: '100vw', backgroundColor: 'whitesmoke', opacity: 0.5, zIndex: 9999 }}>
            <Spin >
              <div id="loading"></div>
            </Spin>
          </Flex>
          {/* Page Content max width 1024 px*/}
          <div style={{ maxWidth: maxWidth, margin: '0 auto' }}>
            <MyRoutes />
            <FunctionalFloatButtonGroup></FunctionalFloatButtonGroup>
          </div>
          <AppFooter />
        </ConfigProvider>
      </>
    )
  );
};

export default App;