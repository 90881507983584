import { IAlterMemberInfoRequest, SendRegistOTPRequest } from "@/interfaces/Requests";
import { IMemberInfo } from "@/interfaces/Responses";
import useAPI from "@/lib/customHooks/useAPI";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";

export const useValidateLoginApi = (merchantId: BigInt) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/ValidateLogin?merchantId=${merchantId}`);

export const useGetMemberInfoApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IMemberInfo>>(`${process.env.BASE_API_URL}/api/Portal/GetMemberInfo`, {
    signal
});

export const useSendRegistOTPApi = (request: SendRegistOTPRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/SendRegistOTP`, {
    method: "POST",
    body: request
});

export const useAlterMemberInfoApi = (request: IAlterMemberInfoRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Portal/AlterMemberInfo`, {
    method: "POST",
    body: request
});
