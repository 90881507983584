import { useMemberRegistApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { RegistTypeEnum } from "@/Templates/enums/templateEnums";
import { IRegistRequestDto } from "@/Templates/interfaces/templatesInterfaces";
import { LockOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, InputRef } from "antd";
import Title from "antd/es/typography/Title";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

const RegistPage = () => {
  const { messageApi, merchantId, sendGAEvent } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const navigate = useNavigate();
  const [register, set_register] = useState<IRegistRequestDto>({
    merchantId: merchantId,
    userName: "",
    email: "",
    registType: RegistTypeEnum.Email,
    phoneNumber: "",
    hashPassword: "",
    confirmPassword: "",
  });
  const [form] = Form.useForm();
  const mailRef = useRef<InputRef>(null);
  const formValuesChange = (fieldValues: any, allValues: any) => {
    set_register({ ...register, ...fieldValues });
  };
  const validateConfirmPassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue("hashPassword")) {
      return Promise.reject(translate('The two passwords that you entered do not match'));
    }
    return Promise.resolve();
  };
  const { mutate: registMutate } = useMutation(async (request: IRegistRequestDto) => useMemberRegistApi(request), {
    onSuccess: (response) => {
      if (response.isSuccess) {
        sendGAEvent(
          'sign_up', {
          method: 'Platform',
        }
        );
        messageApi.success(translate('Operation success')).then(() => navigate(`/${merchantId}/login`));
      }
      else {
        messageApi.error(translate(response.message || 'Operation failed'));
      }
    }
  });

  const onFinish = (value: any) => {
    var request: IRegistRequestDto = {
      ...register,
      hashPassword: MD5(register.hashPassword).toString().toLowerCase(),
    };
    registMutate(request);
  };

  useEffect(() => {
    mailRef.current?.focus();
  }, []);

  return (
    <div className="flex justify-center items-center h-[100vh]">
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={(part, all) => formValuesChange(part, all)}
      >
        <Flex justify="center">
          <Title level={3}>{translate('Regist')}</Title>
        </Flex>
        <Form.Item
          name="userName"
          rules={[{ required: true, message: translate('is required') }]}
        >
          <Input
            type="text"
            className="w-[500px]"
            placeholder={translate('UserName')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: translate('is required') },
            {
              pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
              message: "必須是有效的信箱格式！",
            },
          ]}
        >
          <Input
            type="text"
            className="w-[500px]"
            prefix={<MailOutlined />}
            placeholder={translate('Email')}
            ref={mailRef}
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          rules={[
            { required: true, message: translate('is required') },
            {
              pattern: /^09[0-9]{8}$/,
              message: "必須是有效的手機格式！",
            },
          ]}
        >
          <Input
            type="text"
            className="w-[500px]"
            prefix={<PhoneOutlined />}
            placeholder={translate('PhoneNumber')}
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          name="hashPassword"
          rules={[
            { required: true, message: translate('is required') },
            {
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
              message:
                translate('Password length should be 8 to 24 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.')
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={translate('Password')}
            className="w-[500px]"
          />
        </Form.Item>

        <Form.Item
          name="confirm-password"
          rules={[
            {
              required: true,
              message: translate('Must match the first password input field'),
            },
            {
              validator: validateConfirmPassword,
            },
          ]}
        >
          <Input.Password
            className="w-[500px]"
            prefix={<LockOutlined />}
            placeholder={translate('Confirm password')}
          />
        </Form.Item>
        <Form.Item>
          <Flex justify="center">
            <Button type="primary"
              disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
              htmlType="submit">
              {translate('Regist')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegistPage;
