import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Empty, Flex, Form, Input, Modal, Row, Space, Tabs } from 'antd';
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { FrontEndDisplayType, LogisticReceviePointType } from '@/enums/Enums';
import { IMemberLogisticOption, IPortalMerchantThirdPartyLogisticSettingViewModel, ISupportMemberFillAddressResponse } from '@/interfaces/Responses';
import {
    useAddMemberLogisticOptionApi,
    useDeleteMemberLogisticOptionApi,
    useFillMemberLogisticOptionWithThirdPartyLogisticApi,
    useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi,
    useGetAnonymousLogisticOptionsApi,
    useGetLogisticSupportPaymentSettingIdsApi,
    useGetLogisticsMainTypesApi,
    useGetMemberLogisticOptionsApi,
    useGetMerchantThirdPartyLogisticSettingViewModes
} from '@/lib/api/apis';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { LocalStorageHelper } from '@/lib/helpers/LocalStorageHelper';
import { UrlHelper } from '@/lib/helpers/UrlHelper';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { IOperationResultT } from '@/Templates/interfaces/templatesInterfaces';
import { GlobalContext } from '../../../lib/contexts/GlobalContext';
import { CheckOutContext } from '../Contexts/ICheckOutContext';
import AddAddressOption from './AddAddressOption';

const { confirm } = Modal;
const { TabPane } = Tabs;

export interface ILogisticSelectorProps {
    nextStep: () => void;
    prevStep: () => void;
}

const LogisticSelector: React.FC<ILogisticSelectorProps> = ({ nextStep, prevStep }) => {
    const localStorageHelper = new LocalStorageHelper();
    const urlHelper = new UrlHelper();
    const { translate } = useContext(TranslationContext);
    const {
        selectedItems,
        selectedLogistic, setSelectedLogistic,
        setSelectedLogisticOption,
        setLogisticValues,
        userWantReservePay, setUserWantReservePay,
        setLogiscticSupportedPaymentSettingIds,
        receiverInfos, setReceiverInfos
    } = useContext(CheckOutContext);
    const [form] = Form.useForm();
    const { messageApi, isLogin, merchantId, tryGetSessionId } = useContext(GlobalContext);
    const [memberLogisticOptions, setMemberLogisticOptions] = useState<IMemberLogisticOption[]>([]);
    const [logisticsMainTypes, setLogisticsMainTypes] = useState<string[]>();
    const [logisticMethods, setLogisticMethods] = useState<IPortalMerchantThirdPartyLogisticSettingViewModel[]>();
    const [filteredLogisticMethods, setFilteredLogisticMethods] = useState<IPortalMerchantThirdPartyLogisticSettingViewModel[]>([]);
    const [selectedMemberLogisticOption, setSelectedMemberLogisticOption] = useState<IMemberLogisticOption | undefined>(undefined);
    const [memberSelectedParameters, setMemberSelectedParameters] = useState<{ [key: string]: string }>({});

    const { refetch: refetchLogisticsMainTypes } = useQuery('getLogisticsMainTypesApi', async () => await useGetLogisticsMainTypesApi(), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setLogisticsMainTypes(response.result);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { data: memberLogisticOptionsApiData, mutate: mutateMemberLogisticOptions, isLoading: isLoadingOptions } = useMutation(
        async (isLogin: boolean) => isLogin ? await useGetMemberLogisticOptionsApi(merchantId) : await useGetAnonymousLogisticOptionsApi(tryGetSessionId()),
        {
            onSuccess: (data) => {
                data?.isSuccess && data.result && setMemberLogisticOptions(data.result)
            }
        }
    );

    const { refetch: refetchLogisticMethods } = useQuery('getMerchantThirdPartyLogisticSettingViewModes', async () => await useGetMerchantThirdPartyLogisticSettingViewModes(merchantId), {
        enabled: false,
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setLogisticMethods(response.result);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    useEffect(() => {
        refetchLogisticMethods();
        refetchLogisticsMainTypes();
        mutateMemberLogisticOptions(isLogin);
    }, []);

    useEffect(() => {
        setMemberSelectedParameters({});
        setLogisticValues(undefined);
    }, [selectedLogistic]);

    useEffect(() => {
        if (selectedLogistic) {
            useGetLogisticSupportPaymentSettingIdsApi(selectedLogistic?.thirdPartySettingId!).then((response) => {
                setSelectedLogisticOption(selectedMemberLogisticOption);
                setUserWantReservePay(userWantReservePay);
                if (response.isSuccess && response.result) {
                    setLogiscticSupportedPaymentSettingIds(response.result);
                }
            });
        }
    }, [selectedMemberLogisticOption, userWantReservePay]);

    useEffect(() => {
        if (logisticMethods && selectedItems.length > 0) {
            const itemLogisticType = selectedItems[0].logisticOrderLimitType;
            const filtered = logisticMethods.filter(method =>
                method.logisticOrderLimitType === itemLogisticType
            );
            setFilteredLogisticMethods(filtered);
        }
    }, [logisticMethods, selectedItems]);

    const handleSelectLogisticOption = (option: IMemberLogisticOption) => {
        setSelectedMemberLogisticOption(option === selectedMemberLogisticOption ? undefined : option);
    };

    const handleMemberSelectedParameters = (key: string, value: string) => {
        const updatedParameters = { ...memberSelectedParameters, [key]: value };
        setMemberSelectedParameters(updatedParameters);
        setLogisticValues(updatedParameters);
    };

    const handleAddMemberLogisticOption = async (values: Record<string, string>) => {
        await useAddMemberLogisticOptionApi({ thirdPartyLogisticId: selectedLogistic?.thirdPartySettingId!, values }).then((res) => {
            if (res.isSuccess) {
                mutateMemberLogisticOptions(isLogin);
            }
        });
    };

    const handleInputChange = (input: string, fieldName: string) => {
        setReceiverInfos({ ...receiverInfos, [fieldName]: input });
    };

    const handleAddStoreAddressOption = async (logisticVM: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined) => {
        if (!logisticVM) return;

        const frontEndValues: { [key: string]: string } = {
            ...memberSelectedParameters,
            'MerchantID': merchantId.toString(),
            'IsCollection': logisticVM.isSupportReservePay ? userWantReservePay.toString() : 'false',
            'FrontEndHostDomain': `${window.location.protocol}//${window.location.host}`
        };

        const apiFunction = isLogin ? useFillMemberLogisticOptionWithThirdPartyLogisticApi : useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi;
        const requestParams = {
            merchantId,
            thirdPartyLogisticId: selectedLogistic?.thirdPartySettingId!,
            frontEndValues,
            sessionId: tryGetSessionId()
        };

        const response = await apiFunction(requestParams);
        fillAddressAfterSteps(response);
    };

    const fillAddressAfterSteps = (response: IOperationResultT<ISupportMemberFillAddressResponse>) => {
        if (!response.isSuccess || !response.result?.data) {
            alert(response.message);
            return;
        }

        localStorageHelper.setWithExpiry("selectedIShoppingCartItemViewModels", selectedItems, 1000 * 60 * 60 * 24 * 7);
        localStorageHelper.setWithExpiry("filledReceiverInfos", receiverInfos, 1000 * 60 * 60 * 24 * 7);

        const data = JSONBig.parse(response.result?.data);
        switch (response.result?.type) {
            case FrontEndDisplayType.PageFormPost:
                urlHelper.pageFormPost(response.result?.url, data);
                break;
            case FrontEndDisplayType.PageRedirectURL:
                urlHelper.openRedirect(response.result?.url, data);
                break;
            case FrontEndDisplayType.OpenNewPage:
                urlHelper.openInPopup(response.result?.url, data);
                break;
            default:
                break;
        }
    };

    const handleDeleteMemberLogisticOption = async (memberLogisticOptionId: BigInt) => {
        confirm({
            title: translate('Are you sure you want to delete this option') + '?',
            icon: <ExclamationCircleFilled />,
            okText: translate('Yes'),
            okType: 'danger',
            cancelText: translate('Cancel'),
            async onOk() {
                await useDeleteMemberLogisticOptionApi({ sessionId: tryGetSessionId(), memberLogisticOptionId }).then((res) => {
                    if (res.isSuccess) {
                        messageApi.success(translate('Operation success'));
                        mutateMemberLogisticOptions(isLogin);
                    }
                });
            }
        });
    };

    const handleNextStep = () => {
        nextStep();
    };

    const handlePrevStep = () => {
        setSelectedLogistic(undefined);
        setLogisticValues(undefined);
        prevStep();
    };

    const renderReceiverInfoForm = () => (
        <Form layout={'vertical'} form={form}>
            <Form.Item label={translate('Receiver') + ' ' + translate('Name')}>
                <Input placeholder="Name" defaultValue={receiverInfos.receiverName} onChange={(e) => handleInputChange(e.target.value, 'receiverName')} />
            </Form.Item>
            <Form.Item label={translate('Receiver') + ' ' + translate('PhoneNumber')}>
                <Input placeholder="PhoneNumber" value={receiverInfos.receiverPhone} onChange={(e) => handleInputChange(e.target.value, 'receiverPhone')} />
            </Form.Item>
            <Form.Item label={translate('Receiver') + ' ' + translate('Email')}>
                <Input placeholder="Email" value={receiverInfos.receiverEmail} onChange={(e) => handleInputChange(e.target.value, 'receiverEmail')} />
            </Form.Item>
        </Form>
    );

    const renderLogisticMethods = () => {
        if (!filteredLogisticMethods || filteredLogisticMethods.length === 0) {
            return <Empty description={translate('No available logistics methods for the selected items')} />;
        }

        const groupedMethods = filteredLogisticMethods.reduce((acc, method) => {
            if (!acc[method.logisticsMainType]) {
                acc[method.logisticsMainType] = [];
            }
            acc[method.logisticsMainType].push(method);
            return acc;
        }, {} as { [key: string]: typeof filteredLogisticMethods });

        return (
            <Tabs
                centered
                type='card'
                defaultActiveKey={Object.keys(groupedMethods)[0]}>
                {Object.entries(groupedMethods).map(([logisticsMainType, methods]) => (
                    <TabPane
                        tab={translate(logisticsMainType)} key={logisticsMainType}>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col span={24}>
                                <Flex justify='center' align='center' gap={'small'} wrap='wrap'>
                                    {methods.map((logistic) => (
                                        <Button
                                            key={logistic.id.toString()}
                                            type={selectedLogistic?.id === logistic.id ? 'primary' : 'default'}
                                            onClick={() => setSelectedLogistic(logistic)}
                                        >
                                            {logistic.providerName}
                                        </Button>
                                    ))}
                                </Flex>
                            </Col>
                        </Row>
                        {selectedLogistic && renderLogisticOptions(selectedLogistic)}
                    </TabPane>
                ))}
            </Tabs>
        );
    };

    const renderLogisticOptions = (logistic: IPortalMerchantThirdPartyLogisticSettingViewModel) => (
        <div style={{ padding: '20px' }}>
            {
                Object.entries(logistic.memberSelectFieldsForFillMemberAddress).map(([key, values]) => (
                    <React.Fragment key={key}>
                        <Divider>{translate(key)}</Divider>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col span={24}>
                                <Flex justify='center' align='center' gap={'small'} wrap='wrap'>
                                    {values.map(value => (
                                        <Button
                                            key={value}
                                            type={memberSelectedParameters[key] === value ? 'primary' : 'default'}
                                            onClick={() => handleMemberSelectedParameters(key, value)}
                                        >
                                            {translate(`${logistic.providerName}.${value}`, 'LogisticsSubType')}
                                        </Button>
                                    ))}
                                </Flex>
                            </Col>
                        </Row>
                    </React.Fragment>
                ))
            }
            {(Object.entries(logistic.memberSelectFieldsForFillMemberAddress).length === 0 ||
                Object.entries(memberSelectedParameters).length === Object.entries(logistic.memberSelectFieldsForFillMemberAddress).length) && renderStoreAddressOptions(logistic)}
            {logistic.logisticReceviePointType === LogisticReceviePointType.ConvenienceStore && logistic?.isSupportReservePay && renderReservePayOptions()}
        </div>
    );

    const renderStoreAddressOptions = (logistic: IPortalMerchantThirdPartyLogisticSettingViewModel) => {
        const filteredOptions = memberLogisticOptions.filter(option =>
            option.thirdPartyLogisticId === logistic.thirdPartySettingId &&
            Object.entries(memberSelectedParameters).every(([key, value]) => option.values[key] === value)
        );

        return (
            <>
                <Divider>{translate('Store')} {translate('Address')}</Divider>
                <Row justify="center" gutter={[16, 16]}>
                    <Col span={24}>
                        <Row justify="center">
                            <Space wrap>
                                {filteredOptions.length > 0 ? (
                                    filteredOptions.map((memberLogisticOption) => (
                                        <Button
                                            key={memberLogisticOption.id.toString()}
                                            type={memberLogisticOption === selectedMemberLogisticOption ? 'primary' : 'default'}
                                            onClick={() => handleSelectLogisticOption(memberLogisticOption)}
                                        >
                                            <CloseOutlined onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteMemberLogisticOption(memberLogisticOption.id);
                                            }} />
                                            {memberLogisticOption.displayName}
                                        </Button>
                                    ))
                                ) : (
                                    <Empty description={translate('No data')} />
                                )}
                            </Space>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: '16px' }}>
                    <Col>
                        {logistic.logisticsMainType === 'HomeDelivery' ? (
                            <AddAddressOption NewMemberLogisticOptionValuesEvnet={handleAddMemberLogisticOption} />
                        ) : (
                            <Button onClick={() => handleAddStoreAddressOption(logistic)}>
                                {translate('Add')} {translate('Address')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </>
        );
    };

    const renderReservePayOptions = () => (
        <>
            <Divider>{translate('Reserve Pay')}</Divider>
            <Row justify="center" gutter={[16, 16]}>
                <Col>
                    <Space>
                        <Button
                            type={userWantReservePay ? 'primary' : 'default'}
                            onClick={() => setUserWantReservePay(true)}
                        >
                            {translate('Reserve')}
                        </Button>
                        <Button
                            type={!userWantReservePay ? 'primary' : 'default'}
                            onClick={() => setUserWantReservePay(false)}
                        >
                            {translate('No Reserve')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </>
    );

    if (isLoadingOptions) {
        return <LoadingComponent />;
    }

    return (
        <Flex justify={'center'}>
            <Row style={{ width: '80%' }}>
                <Col span={24}>
                    <Divider>{translate('Receiver Infos')}</Divider>
                    {renderReceiverInfoForm()}
                </Col>
                <Col span={24}>
                    {renderLogisticMethods()}
                </Col>
                <Col xs={24} style={{ marginTop: '20px' }}>
                    <Flex justify='space-between'>
                        <Button shape="round" danger onClick={handlePrevStep}>
                            {translate('Previous Step')}
                        </Button>
                        <Button shape="round" type="primary" disabled={!selectedMemberLogisticOption} onClick={handleNextStep}>
                            {translate('Next Step')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </Flex>
    );
};

export default LogisticSelector;