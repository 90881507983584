export enum MerchantPortalStyleSettingType {
    None = 'None',
    Components = 'Components',
    Pages = 'Pages'
}

export enum NavBarClickType {
    None = 'None',
    RedirectPage = 'RedirectPage',
    ItemSearch = 'ItemSearch',
    RedirectItemDetailPage = 'RedirectItemDetailPage',
}

export enum MerchantCheckoutType {
    // 需註冊
    Normal = "Normal",
    // 允許加入臨時購物車，結帳需要會員
    AllowTempShoppingCart = "AllowTempShoppingCart",
    // 允許不註冊結帳
    AllowNoneRegist = "AllowNoneRegist",
}

export enum MarqueeType {
    /// <summary>
    /// 商戶主頁
    /// </summary>
    MerchantMain = 'MerchantMain',
    /// <summary>
    /// 系統使用
    /// </summary>
    SystemUsage = 'SystemUsage',
}

export enum MerchantPortalStyleShopPages {
    LandingPage = 'LandingPage',
    HomePage = 'HomePage',
    SearchPage = 'SearchPage',
    ItemDetailPage = 'ItemDetailPage',
    ShoppingCartPage = 'ShoppingCartPage',
}

export enum MerchantPortalStyleShopComponents {
    ItemCard = 'ItemCard',
    NavBar = 'NavBar',
    MainMarquee = 'MainMarquee',
    ScrollToTopBtn = 'ScrollToTopBtn',
}

export enum DeviceType {
    Mobile = 'Mobile',
    LapTop = 'LapTop',
    Desktop = 'Desktop',
}

export enum RegistTypeEnum {
    /// <summary>
    /// 信箱
    /// </summary>
    Email = "Email",
    /// <summary>
    /// 手機
    /// </summary>
    PhoneNumber = "PhoneNumber",
}

export enum ConfigFieldType {
    Color = 'Color',
    Select = 'Select',
    Number = 'Number',
    Input = 'Input',
    Upload = 'Upload',
}