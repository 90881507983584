import { IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from "@/lib/customHooks/useAPI";

export const useGetShoppingCartCountApi = (signal?: AbortSignal) => useAPI<IOperationResultT<number>>(`${process.env.BASE_API_URL}/api/Portal/GetShoppingCartCount`, {
    signal
});

export const useGetTempShoppingCartCountApi = (merchantId: BigInt, params: BigInt | undefined, signal?: AbortSignal) => useAPI<IOperationResultT<number>>(`${process.env.BASE_API_URL}/api/Portal/GetTempShoppingCartCount?merchantId=${merchantId}&sessionId=${params}`, {
    signal
});
